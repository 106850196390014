//
// Input Group
//

// Form control
.input-group {
    &.input-group-solid {
        @include border-radius($input-border-radius);

        &.input-group-sm {
            @include border-radius($input-border-radius-sm);
        }

        &.input-group-lg {
            @include border-radius($input-border-radius-lg);
        }

        .input-group-text {
            background-color: var(--kt-input-solid-bg);
            border-color: var(--kt-input-solid-bg);

            &+.form-control {
                border-left-color: var(--kt-input-border-color);
            }
        }

        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }

        .form-control {
            background-color: var(--kt-input-solid-bg);
            border-color: var(--kt-input-solid-bg);

            &+.input-group-text {
                border-left-color: var(--kt-input-border-color);
            }
        }
    }
}