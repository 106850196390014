// Image thumbnails
.img-thumbnail {
	background-color: var(--kt-thumbnail-bg);
	border: $thumbnail-border-width solid var(--kt-thumbnail-border-color);
	@include box-shadow(var(--kt-thumbnail-box-shadow));
}

.selected {
	border: 2px solid #007bff;
	/* Change to your desired border color */
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	/* Adjust shadow values as needed */
}

.figure-caption {
	color: var(--kt-figure-caption-color);
}